import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";

const LegalsNav = () => {
    return(
        <StaticQuery
            query={graphql`
                query {
                    allWpMenuItem(
					    sort: {order: ASC, fields: order}
					    filter: {locations: {eq: LEGALS}, parentId: {eq: null}}
					  ) {
                        nodes {
                            label
					        path
                        }
                    }
                }
            `}
            render={data => {
                if (data.allWpMenuItem.nodes) {
                    return (
						<nav>	
							<ul>					
							  {data.allWpMenuItem.nodes.map((item, i) => (
			          	  		<li key={`${i}-item`}>
									<Link to={item.path}>{item.label}</Link>									
				                </li>
				              ))}
							</ul>
						</nav>
                    )
                }
            }}
        />
    )
}

export default LegalsNav
