import React, { useState } from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Nav from './Nav' 

export default function Header() {

  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => setMenuVisible(previousState => !previousState);

    return(
        <StaticQuery
            query={graphql`
                query {
				  wp {
				    generalSettings {
				      title
				    }
				  }
                }
            `}
            render={data => {
                return (
        			<header id="header" className={menuVisible ? 'navopen' : ''}>
					    <div className="top">
	  						<Link
				              to="/"
				              title={data.wp.generalSettings.title}
					          id="logo" />
	
							<button id="nav-trigger" className="plain" onClick={toggleMenu} aria-label="Navigation">
								<span></span>
							    <span></span>
								<span></span>
								<span></span>
							</button>
						
					    </div>
					    <Nav />
		
        			</header>
                )
            }}
        />
    )
}

