import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import FacebookImg from "../assets/svg/inline/ic_facebook.svg"
import XImg from "../assets/svg/inline/ic_x.svg"
import LinkedInImg from "../assets/svg/inline/ic_linkedin.svg"

const customLink = function(item) {
  const internal = /^\/(?!\/)/.test(item.path)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
	  <Link to={item.path}>
		{item.label}
	  </Link>
    )
  }
  return (
    <a href={item.path} target="_blank" rel="noreferrer">
      {item.label}
    </a>
  )
}

const FooterNav = () => {
    return(
        <StaticQuery
            query={graphql`
                query {
                    allWpMenuItem(
					    sort: {order: ASC, fields: order}
					    filter: {locations: {eq: FOOTER}, parentId: {eq: null}}
					  ) {
                        nodes {
                            label
							childItems {
					        	nodes {
					          		path
					          		label
						            cssClasses
						            target
					        	}
					      	}
                        }
                    }
                }
            `}
            render={data => {
                if (data.allWpMenuItem.nodes) {
                    return (
						<nav>						
							{/*the top level items are treated as titles*/}
							{data.allWpMenuItem.nodes.map((title, i) => (
			          	  		<dl key={`${i}-title`}>
									<dt>{title.label}</dt>
									{
				                    	title.childItems.nodes.length
				                    	? <>
											{title.childItems.nodes.map((item, j) => (
												<dd key={`${j}-item`}>
													{
														item.target==='_blank'
														? <a href={item.path} target="_blank" rel="noreferrer">
															{
																item.cssClasses?.length && item.cssClasses[0]==='facebook'
																? <FacebookImg className="social" />
																: null
															}
															{
																item.cssClasses?.length && item.cssClasses[0]==='twitter'
																? <XImg className="social" />
																: null
															}
															{
																item.cssClasses?.length && item.cssClasses[0]==='linkedin'
																? <LinkedInImg className="social" />
																: null
															}
															{item.label}
														  </a>
														: customLink(item)  
													}
				               					</dd>
						            		))}
										  </>
				                    	: null
			                    	}
				                </dl>
				            ))}
						</nav>
                    )
                }
            }}
        />
    )
}

export default FooterNav
