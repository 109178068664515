import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from "prop-types";
import "../style/style.scss";
import Header from './Header' 
import Footer from './Footer' 
import AnnouncementBar from './AnnouncementBar' 
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';

const Layout = ({ children, className }) => {

  const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
					        localFile {
					          publicURL
					        }
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `);

    const options = {
        schemaReplacement: {
                from: seo?.schema?.logo?.sourceUrl,
                to: 'https://landbay.co.uk/'+seo?.schema?.logo?.localFile?.publicURL
            }
    }

  return(
    <SEOContext.Provider value={{ global: seo, options }}>
      <AnnouncementBar />
      <Header />
      <main className={className}>{children}</main>
      <Footer />
    </SEOContext.Provider>  
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired
};

export default Layout;


export const PageSEOFragment = graphql`
	fragment PageSEOFragment on WpPage {
	  seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
                altText
                sourceUrl
                srcSet
		        localFile {
		          publicURL
		        }
            }
            twitterTitle
            twitterDescription
            twitterImage {
                altText
                sourceUrl
                srcSet
		        localFile {
		          publicURL
		        }
            }
            canonical
            cornerstone
            schema {
                articleType
                pageType
                raw
            }
        }
	}
`
export const PostSEOFragment = graphql`
	fragment PostSEOFragment on WpPost {
	  seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
                altText
                sourceUrl
                srcSet
		        localFile {
		          publicURL
		        }
            }
            twitterTitle
            twitterDescription
            twitterImage {
                altText
                sourceUrl
                srcSet
		        localFile {
		          publicURL
		        }
            }
            canonical
            cornerstone
            schema {
                articleType
                pageType
                raw
            }
        }
	}
`
