import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import CloseIcon from "../assets/svg/inline/ic_close.svg"
import useCookie from 'react-use-cookie';


export default function AnnouncementBar() {
  const [announcementOpen, setAnnouncementOpen] = useState(true);
  const [hideAnnouncementCookie, setHideAnnouncementCookie] = useCookie('announcement', '0');
  
  const checkPagePath = function(display) {
	const path = typeof window !== 'undefined' ? window.location.pathname : '';

	if (display==='homepage' && path==='/') {
		return true;
	}
	if (path.indexOf('/intermediaries')===0) {
		return true;
	}
	return false;
  }
  
  const closeAnnouncement = function() {
	setAnnouncementOpen(false);
	
	//set the cookie
	setHideAnnouncementCookie('1', {
	  days: 1,
	  SameSite: 'Strict',
	  Secure: true,
	});
  }
  

  return(
    <StaticQuery
        query={graphql`
            query {
			  wp {
			    acfOptionsOptions {
			      options {
			        announcementBarEnabled
			        announcementBarContent
			        announcementBarDisplay
			      }
			    }
			  }
            }
        `}
        render={data => {
			if (data.wp.acfOptionsOptions.options.announcementBarEnabled!==true) {
				return null;
			}
			if (!checkPagePath(data.wp.acfOptionsOptions.options.announcementBarDisplay)) {
				return null;
			}
			if (!announcementOpen || hideAnnouncementCookie==='1') {
				return null;
			}

            return (
    			<div id="announcement-bar">
				    <div className="internal" dangerouslySetInnerHTML={{__html: data.wp.acfOptionsOptions.options.announcementBarContent}} />
				    <button className="plain close" aria-label="close" onClick={closeAnnouncement}><CloseIcon /></button>
    			</div>
            )
        }}
    />
  )
}

