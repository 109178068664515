import React, {useRef, useEffect, useCallback} from "react";
import Button from '../Button';
import { gsap } from "gsap";
import ScrollTrigger from "../../assets/plugins/ScrollTrigger";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger); 
}

export default function Hero({widget}) {
/*layout
  subtitle
  title
  buttons {
    buttonStyle
    button {
      target
      title
      url
    }
  }
  images {
	mobileImage {
      localFile {
        childImageSharp {
          gatsbyImageData(
            sizes: "100vw"
            breakpoints: [750]
          )
        }
      }
      altText
    }
    desktopImage {
      localFile {
        childImageSharp {
          gatsbyImageData(
            sizes: "(min-width: 768px) 1280px"
            breakpoints: [2560]
          )
        }
      }
      altText
    }
  }*/
//console.log(widget);

  const buttonStyles = ['black solid', 'white solid'];
  const trigger = useRef();
  const shape1 = useRef();
  const shape2 = useRef();
  const shape3 = useRef();
  const shape4 = useRef();

  useEffect(() => {
	const tl = gsap.timeline({
        scrollTrigger: {
            trigger: trigger.current,
            //markers: true,
            start: "top-=88 top",
            end: "bottom top",
            invalidateOnRefresh: true,//https://greensock.com/st-mistakes/#update-refresh
            scrub: true,
	    }
    });
    if (widget.layout==='default' || widget.layout==='small') {
	  tl.to(shape1.current, {top: -50})
        .to(shape2.current, {top: -100}, 0)
        .to(shape3.current, {top: -200}, 0)
        .to(shape4.current, {top: -300}, 0);
    } else if (widget.layout==='mini' || widget.layout==='inner') {
	  tl.to(shape1.current, {top: -25})
        .to(shape2.current, {top: -50}, 0)
        .to(shape3.current, {top: -75}, 0);
	}
  }, [widget]);

  /**
   * Gets the CSS classes for this widget, 
   * based on the widget layout and whether 
   * we have a background image set
   */
  const getCssClasses = useCallback(() => {
	if (widget.images?.desktopImage) {
	  return 'widget hero background-image '+widget.layout;
    }
    return 'widget hero '+widget.layout;
  }, [widget]);

    return(
		<section className={getCssClasses()} ref={trigger}>	
			{
			  widget.layout==='inner'
			  ? <div className="stripe">
				  <div className="shapes">
				    <div className="shape shape1" ref={shape1}></div>
				    <div className="shape shape2" ref={shape2}></div>
				    <div className="shape shape3" ref={shape3}></div>
				    <div className="shape shape4" ref={shape4}></div>
				  </div>
				</div>
			  : null
			}
			<div className="internal">
				<div className="content">
					{
					  widget.title
					  ? <h1>{widget.title}</h1>	
					  : null
					}
					{
					  widget.layout==='default' && widget.subtitle && (
						<p>{widget.subtitle}</p>	
					)}
					{
					  widget.layout==='inner' && widget.content && (
						<div className="copy" dangerouslySetInnerHTML={{ __html: widget.content }} />
					)}
					{
					  widget.buttons
					  ? <div className="buttons">
						  {widget.buttons.map((button, i) => (
							<Button key={`button${i}`} link={button.button} className={widget.layout==='default' ? buttonStyles[i] : 'purple solid'} />
						  ))}
						</div>
					  : null
					}
				</div>
			</div>
			{
			  widget.layout!=='inner'
			  ? <div className="shapes">
				  <div className="shape shape1" ref={shape1}></div>
				  <div className="shape shape2" ref={shape2}></div>
				  <div className="shape shape3" ref={shape3}></div>
				  <div className="shape shape4" ref={shape4}></div>
				</div>
			  : null
			}
			{widget.images?.mobileImage && (
				<GatsbyImage className="mobile-image" image={getImage(widget.images.mobileImage.localFile)} alt={widget.images.mobileImage.altText} />
			)}
			{widget.images?.desktopImage && (
				<GatsbyImage className="desktop-image" image={getImage(widget.images.desktopImage.localFile)} alt={widget.images.desktopImage.altText} />
			)}
		</section>
    )
}

