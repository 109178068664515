import React, { useState, useCallback } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import AccountNav from './AccountNav' 
import SubNav from './SubNav' 
import Collapse from "@kunukn/react-collapse";

const Nav = () => {

  const [activeItem, setActiveItem] = useState(false);

  const isDesktop = (typeof window !== 'undefined') && window.innerWidth>=1280;

  /**
   * Function to preload some images so they don't pop in when we navigate to the relevant screen
Check this resource for animating the height:
https://stackoverflow.com/questions/3795481/javascript-slidedown-without-jquery
   */
  const setActiveSubmenu = useCallback((event, item, index) => {
	//if we're on a device that supports touch, and the element has a sub menu, 
	//we prevent the navigation and instead display the submenu
	if (!isDesktop && item.childItems.nodes.length) {		
		event.preventDefault();
	    //console.log('in setActiveSubmenu', index, item)
		//set the active item		
		if (activeItem===index) {
			setActiveItem(false);
		} else {
			setActiveItem(index);
		}
	}
  }, [isDesktop, activeItem]);

  const query = useStaticQuery(graphql`
    query {
	  allWpMenuItem(
	    sort: {order: ASC, fields: order}
	    filter: {locations: {eq: MAIN}, parentId: {eq: null}}
	  ) {
        nodes {
          path
          label
          childItems {
        	nodes {
      		  path
      		  label
	          menu {
	            menuitemType
	            menuitemImage {
	              description
	              image {
	                localFile {
					  childImageSharp {
				        gatsbyImageData(
						  sizes: "200px",
						  breakpoints: [400]
				        )
				      }
	                }
	              }
	            }
		      }
        	}
	      }
	    }
	  }
	}
  `)

  if (!query?.allWpMenuItem?.nodes?.length>0) {
	return null;
  }

  return(
	<nav>
		<ul className="primary">
			{query.allWpMenuItem.nodes.map((item, i) => {
				let classNames = [];
				if (item.childItems.nodes.length) {
					classNames.push('has-submenu');
				}
				if (activeItem===i) {
					classNames.push('active');
				}
				return (
          	  		<li 
						key={`${i}-item`} 
						className={classNames.join(' ')}>
						<Link to={item.path} onClick={(event) => setActiveSubmenu(event, item, i)} activeClassName="active">
	                   	 {item.label}
						</Link>	
						{/*Do we have some child menu items?*/}
						{
	                    	item.childItems.nodes.length
	                    	? <Collapse isOpen={activeItem===i}><SubNav menuItems={item.childItems.nodes} /></Collapse>
	                    	: null
                    	}
	                </li>
				);
            })}	
		</ul>
		<AccountNav />
	</nav>
  )
}

export default Nav
