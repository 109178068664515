import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function SubNav({menuItems}) {
  
const customLink = function(item) {
  const internal = /^\/(?!\/)/.test(item.path)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
	  <Link to={item.path} activeClassName="active">
		{item.label}
	  </Link>
    )
  }
  return (
    <a href={item.path} target="_blank" rel="noreferrer">
      {item.label}
    </a>
  )
}

  return (
	<div className="submenu">
	  <div className="internal">
		
	      <dl>
		    {/*do we have a title?*/}	
		    {menuItems.map((item, i) => {
			  if (item.menu.menuitemType==='Title') {
			    return(<dt key={`${i}-subitem`}>{item.label}</dt>);
			  }
			  return null;
		    })}

	
		    {/*any items that are mobile-only*/}	
		    {menuItems.map((item, i) => {
			  if (item.menu.menuitemType==='Mobile-only') {
				return(
	  	  		  <dt key={`${i}-subitem`} className="mobile-only">
					{customLink(item)}
	              </dt>
				);
			  }
			  return null;
		    })}
	
	
			{menuItems.map((item, i) => {
			  if (item.menu.menuitemType==='Normal') {
				return(
	  	  		  <dd key={`${i}-subitem`}>
					{customLink(item)}
	              </dd>
				);
			  }
			  return null;
		    })}
		  </dl>

		

		    {menuItems.map((item, i) => {
			  if (item.menu.menuitemType==='Image') {
				return(
	  	  		  <div className="box" key={`${i}-subitem`}>
	  	  		    {
						/^\/(?!\/)/.test(item.path)
						? <Link to={item.path}>
		   	 				<h4>{item.label}</h4>
		   	 				<p>{item.menu.menuitemImage.description}</p>
		   	 				{/*item.menu.menuitemImage.image.localFile.childImageSharp.fixed*/}
							<div className="img">
		   	 					<GatsbyImage image={getImage(item.menu.menuitemImage.image.localFile)} alt={item.menu.menuitemImage.description} />
							</div>
						</Link>	
						: <a href={item.path}>
		   	 				<h4>{item.label}</h4>
		   	 				<p>{item.menu.menuitemImage.description}</p>
		   	 				{/*item.menu.menuitemImage.image.localFile.childImageSharp.fixed*/}
							<div className="img">
		   	 					<GatsbyImage image={getImage(item.menu.menuitemImage.image.localFile)} alt={item.menu.menuitemImage.description} />
							</div>
						</a>	
					}
	              </div>
				);
			  }
			  return null;
		    })}
	    
	  </div>
	</div>
  );
}
