import React from 'react';
import { Link } from "gatsby";

export default function Button({link, className}) {
  if (link) {
    if (typeof link.onClick === 'function') {
	  return (<button className={`button ${className}`} onClick={link.onClick}>{link.title}</button>);
    }
    if (link.target && link.target==='_blank') {
	  return (<a href={link.url} target="_blank" rel="noreferrer" className={`button ${className}`}>{link.title}</a>);
    }
    return (
      <Link to={link.url} className={`button ${className}`}>{link.title}</Link>
    );
  }
  return null;
}
