import React from "react";
import { graphql, StaticQuery } from "gatsby";

export default function AccountNav() {

  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedIn = false;
  
  return (
      <StaticQuery
        query={graphql`
          {
	        allWpMenuItem(
		      sort: {order: ASC, fields: order}
		      filter: {locations: {eq: LOGGEDOUT}}
		    ) {
              nodes {
                path
                label
      			cssClasses
              }
            }
		  }
        `}
        render={data => {
	          
	    	if (isLoggedIn) {
	        	  return(
	        		  <ul className="secondary">
	 		             <li>logged in nav here</li>
	 		          </ul>	  
	        	  );
			} else {
	        	  return(
	        		  <ul className="secondary">
	 		             {data.allWpMenuItem.nodes.map((item, i) => (
		          	  		<li key={`${i}-item`}>
								<a href={item.path} target="_blank" rel="noreferrer" className={item.cssClasses.join(" ")}>
			                   	 {item.label}
								</a>	
			                </li>
			             ))}	
	 		          </ul>	  
	        	  );
	        }
          
        }}
      />
  );
}
