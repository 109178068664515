import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import FooterNav from './FooterNav' 
import LegalsNav from './LegalsNav' 

export default function Footer() {

    return(
        <StaticQuery
            query={graphql`
                query {
				  wp {
				    generalSettings {
				      title
				    }
				    acfOptionsOptions {
				      options {
				        footerCopyright
				        footerLegals
				      }
				    }
				  }
                }
            `}
            render={data => {
                return (
        			<footer id="footer">
					    <div className="internal">
	  						
					    	<FooterNav />
		
							<div className="legals">
		
	  						  <Link
				                to="/"
				                title={data.wp.generalSettings.title}
					            id="logo2" />

							  <p>
								  {data.wp.acfOptionsOptions.options.footerLegals}
							  </p>

							</div>
		
							<div className="bottom">

							  <p>
								  {data.wp.acfOptionsOptions.options.footerCopyright}
							  </p>					
					
					    	  <LegalsNav />

							</div>
						
					    </div>
        			</footer>
                )
            }}
        />
    )
}

